/* eslint-disable max-lines */
import {ApolloQueryResult, FetchResult, MutationResult} from '@apollo/client'
import {
  AssetOutput,
  ColorMode,
  MultiPageValues,
} from '@myadbox/nebula-template-utils'
import {Access} from '../access/types'
import {ApproverType} from '../approvals/types'
import {Category} from '../categories/types'
import {Dataset} from '../datasets/types'
import {User} from '../profiles/types'
import {ActiveIntegrationType} from '../socialIntegrations/types'

export enum AssetType {
  Audio = `AUDIO`,
  Html = `HTML`,
  Image = `IMAGE`,
  Video = `VIDEO`,
  Template = `TEMPLATE`,
  Other = `OTHER`,
}

export enum EventType {
  Download = `DOWNLOAD`,
  View = `VIEW`,
}

export const cloudinaryResourceTypes = {
  Image: `image`,
  Video: `video`,
  Raw: `raw`,
} as const

export type CloudinaryResourceType =
  (typeof cloudinaryResourceTypes)[keyof typeof cloudinaryResourceTypes]

export const cloudinaryFormats = {
  AAC: `aac`,
  AIFF: `aiff`,
  AI: `ai`,
  EPS: `eps`,
  GIF: `gif`,
  HTML: `html`,
  INDD: `indd`,
  JPG: `jpg`,
  M4A: `m4a`,
  MOV: `mov`,
  MP3: `mp3`,
  MP4: `mp4`,
  OGG: `ogg`,
  PDF: `pdf`,
  PNG: `png`,
  PSD: `psd`,
  SVG: `svg`,
  TIFF: `tiff`,
  Unknown: `unknown`,
  WAV: `wav`,
  WEBM: `webm`,
  WEBP: `webp`,
  ZIP: `zip`,
} as const

export type CloudinaryFormat =
  (typeof cloudinaryFormats)[keyof typeof cloudinaryFormats]

export interface AssetEvent {
  type: EventType
  userId: string
  when: string
}
export interface AssetStats {
  views: number
  downloads: number
  events: AssetEvent[]
}

export interface AssetApprover {
  approverId: string
  approverType: ApproverType
}

export interface FileVersion {
  content?: AssetDerivation
  cloudinaryId: string
  thumbnailId?: string
  previewId?: string
  resourceType?: CloudinaryResourceType
  originalFilename: string
  originalFormat: CloudinaryFormat
  bytes: number
  width?: number
  height?: number
  addedById: string
  pages?: number
  createdAt: string
  exportSizes?: ExportSize[]
  active?: boolean
}

export interface ExportSize {
  quality: string
  format: string
  size: number
}

export interface AppLink {
  appId: string
  refAssetId: string
  cloudinaryId: string
}
export interface AssetLinkage {
  type: ActiveIntegrationType
  apps: AppLink[]
}

export type AssetCreator = Pick<User, `userId` | `fullName` | `avatar`>

export type DerivedFrom = Pick<Asset, `id` | `name`>

type AssetDerivation = {
  preset: string
  width: number
  height: number
  unit: string
  fields?: MultiPageValues
  outputSettings?: AssetOutput
  templateUrl?: string
  secureUrl?: string
}

export interface Asset extends HasTermsExpiry {
  id: string
  derivedFrom?: DerivedFrom
  coverImageId?: string
  type: AssetType
  name: string
  description?: string
  notes?: string
  tags?: string[]
  autoTags?: string[]
  categories?: Category[]
  datasets?: Dataset[]
  accesses: Access[]
  versions: FileVersion[]
  linkages?: AssetLinkage[]
  stats: AssetStats
  approvers?: AssetApprover[]
  createdBy: AssetCreator
  createdAt: string
}

export interface AddAssetsInput extends HasSelectedAccount {
  cloudinaryId: string
  originalFilename: string
  originalFormat?: string
  name?: string
  type: AssetType
  resourceType: string
  tags?: string[]
  description?: string
  datasetIds?: string[]
  accessIds?: string[]
  bytes: number
  width?: number
  height?: number
  secureUrl: string
}

export interface UpdateAssetInput extends HasTermsExpiry {
  name?: string
  description?: string
  notes?: string
  tags?: string[]
  datasetIds?: string[]
  categoryIds?: string[]
  accessIds?: string[]
  approvers?: AssetApprover[]
  context?: Record<string, unknown>
}

export interface UpdateAssetSourceInput {
  cloudinaryId: string
  originalFilename: string
  originalFormat: string
  resourceType: CloudinaryResourceType
  secureUrl: string
  bytes: number
  height?: number
  width?: number
}

export interface HasTermsExpiry {
  terms?: AssetTerms
}

export interface HasSelectedAccount {
  selectedAccount?: {
    id: string
    accountName: string
  }
}

export interface AssetTerms {
  details: string
  expiry?: Date | string
}

export interface UpdateCommonAttributesInput extends HasTermsExpiry {
  assetIds: string[]
  tagsToAdd?: string[]
  tagsToRemove?: string[]
  approversToAdd?: AssetApprover[]
  approversToRemove?: AssetApprover[]
  categoryIdsToAdd?: string[]
  categoryIdsToRemove?: string[]
  datasetIdsToAdd?: string[]
  datasetIdsToRemove?: string[]
}

export interface DeriveAssetInput {
  url: string
  description?: string
  tags?: string[]
  type: AssetType
  derivedFromId: string
  categoryIds?: string[]
  datasetIds?: string[]
  notes?: string
}

export interface ExportSizeInput {
  format: CloudinaryFormat
  quality: string
  size: number
}

export interface VersionUpdateInput {
  exportSizes?: ExportSizeInput[]
  thumbnailId?: string
}

export interface BulkUpdateAssetParams {
  id: string
  name?: string
  description?: string
  notes?: string
  tags?: string[]
  datasetIds?: string[]
  categories?: string[]
  terms?: AssetTerms
}

export type AddAssetsMutator = (
  input: AddAssetsInput[]
) => Promise<FetchResult<Asset[]>>

export type DeriveAssetMutator = (
  input: DeriveAssetInput
) => Promise<FetchResult<string>>

export type DeleteAssetsMutator = (
  assetIds: string[]
) => Promise<FetchResult<{ids: string[]}>>

export type ImportAssetsWithCSVMutator = (
  cloudinaryId: string
) => Promise<FetchResult<boolean>>

export type UpdateAssetMutator = (
  assetId: string,
  input: UpdateAssetInput
) => Promise<FetchResult<{ids: string[]}>>

export type ApplyVersionMutator = (
  assetId: string,
  versionIndex: number
) => Promise<FetchResult<Asset>>

export type UpdateAssetSourceMutator = (
  assetId: string,
  input: UpdateAssetSourceInput
) => Promise<FetchResult<Asset>>

export type UpdateCurrentVersionMutator = (
  assetId: string,
  input: VersionUpdateInput
) => Promise<FetchResult<Asset>>

export type UpdateThumbnailMutator = (
  assetId: string,
  thumbnailId: string
) => Promise<FetchResult<Asset>>

export type RemoveThumbnailMutator = (
  assetId: string
) => Promise<FetchResult<Asset>>

export type InteractWithAssetMutator = (
  assetIds: string[],
  eventType: string
) => Promise<FetchResult<Asset[]>>

export type UpdateCommonAttributesMutator = (
  input: UpdateCommonAttributesInput
) => Promise<FetchResult<Asset[]>>

export type BulkUpdateAssetsMutator = (
  params: BulkUpdateAssetParams[]
) => Promise<FetchResult<Asset[]>>

export interface FetchedAssetsData {
  assetsByIds: Asset[]
}

export type FetchedAssetStats = {
  [key in AssetTypeKeys]?: AssetStat
} & {
  Template?: TemplateAssetStat
}

export type AssetTypeKeys = keyof typeof AssetType

export interface AssetStat {
  count: number
  bytes: number
}

export interface TemplateAssetStat extends AssetStat {
  totalFieldCount: number
  totalPresetCount: number
}

export type AssetTags = {
  assetTags: string[]
}

export type ShareLinkMutator = (
  assetIds: string[]
) => Promise<FetchResult<{createShareLink: ShareLinkResult}>>

export interface ShareLinkResult {
  id: string
  assetIds: string[]
}

export interface UseAssetsOutput {
  fetchAssets(assetIds: string[]): void
  fetchAssetsResults: ApolloQueryResult<FetchedAssetsData>
  fetchAssetUrlWithColorProfile(assetId: string, colorProfile: ColorMode): void
  fetchAssetUrlWithColorProfileResponse: ApolloQueryResult<{
    assetUrlWithColorProfile: string
  }>
  addAssets: AddAssetsMutator
  addAssetsResponse: MutationResult<{addAssets: Asset[]}>
  deriveAsset: DeriveAssetMutator
  deriveAssetResponse: MutationResult<{deriveAsset: string}>
  deleteAssets: DeleteAssetsMutator
  deleteAssetsResponse: MutationResult<{deleteAssets: {ids: string[]}}>
  updateAsset: UpdateAssetMutator
  updateAssetResponse: MutationResult<{updateAsset: Asset}>
  updateAssetSource: UpdateAssetSourceMutator
  updateAssetSourceResponse: MutationResult<{updateAssetSource: Asset}>
  updateCurrentVersion: UpdateCurrentVersionMutator
  updateCurrentVersionResponse: MutationResult<{updateCurrentVersion: Asset}>
  updateThumbnail: UpdateThumbnailMutator
  updateThumbnailResponse: MutationResult<{updateThumbnail: Asset}>
  removeThumbnail: RemoveThumbnailMutator
  removeThumbnailResponse: MutationResult<{removeThumbnail: Asset}>
  importAssetsWithCSV: ImportAssetsWithCSVMutator
  importAssetsWithCSVResponse: MutationResult<{validateBulkData: boolean}>
  interactWithAsset: InteractWithAssetMutator
  interactWithAssetResponse: MutationResult<{interactWithAsset: Asset[]}>
  updateCommonAttributes: UpdateCommonAttributesMutator
  updateCommonAttributesResponse: MutationResult<{
    updateCommonAttributes: Asset[]
  }>
  bulkAssetsExport(): void
  bulkAssetsExportResponse: ApolloQueryResult<{bulkExportAssets: boolean}>
  bulkUpdateAssets: BulkUpdateAssetsMutator
  bulkUpdateAssetsResponse: MutationResult<{bulkUpdateAssets: Asset[]}>
  fetchAssetStats(): void
  fetchAssetStatsResults: ApolloQueryResult<{stats: FetchedAssetStats}>
  fetchShareLink(id: string): void
  fetchShareLinkResponse: ApolloQueryResult<{shareLink: ShareLinkResult}>
  createShareLink: ShareLinkMutator
  createShareLinkResponse: MutationResult<{createShareLink: ShareLinkResult}>
}

export interface UseAssetVersionsOutput {
  applyVersion: ApplyVersionMutator
  applyVersionResponse: MutationResult<{applyVersion: Asset}>
}
