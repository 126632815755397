import {ApolloError} from '@apollo/client'
import {
  InformationCircleIcon,
  TableCellsIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import {Button, Modal, Text} from '@myadbox/gatsby-theme-nebula'
import {toLocaleTimezone} from '@myadbox/gatsby-theme-nebula/utils'
import {NestedSchema} from '@myadbox/nebula-service-api'
import {Link, useI18next} from 'gatsby-plugin-react-i18next'
import {ReactElement} from 'react'
import SchemaDelete from '../SchemaDelete/SchemaDelete'
import DisplayTable from './DisplayTable'

type Props = {
  schemas: NestedSchema[]
  getUsernameById?: (id: string) => string
  timeZone?: string
  profilesLoading?: boolean
  profilesError?: ApolloError
}

const SchemaCardList = ({
  schemas = [],
  getUsernameById,
  timeZone,
  profilesLoading,
  profilesError,
}: Props): ReactElement => {
  const {t, language} = useI18next()

  if (!schemas.length) return null

  return (
    <div
      className={`
        grid
        grid-cols-[--grid-template-columns-var-1]
        gap-4
    `}
      style={{
        '--grid-template-columns-var-1': `repeat(var(--repeat-type-1, auto-fill), minmax(300px, 1fr))`,
        gridAutoRows: `min-content`,
      }}
    >
      {schemas.map(schema => {
        const formattedName = getUsernameById(schema.createdById)
        const formattedDate = toLocaleTimezone(schema.createdAt, {
          timeZone,
          locale: language,
          year: `numeric`,
          month: `numeric`,
          day: `numeric`,
        })

        const createdBy = t(`settings.schemas.schemaList.createdBy`, {
          name: formattedName,
        })
        const createdOn = t(`settings.schemas.schemaList.createdOn`, {
          date: formattedDate,
        })
        return (
          <div
            key={schema.id}
            className={`
              dark:bg-ui-100
              bg-ui-50
              rounded-400
              shadow-card
              flex
              flex-col
              overflow-hidden
            `}
          >
            <div
              className={`
                dark:border-b-ui-200
                flex
                items-baseline
                justify-between
                border-b
                px-3
                py-3
              `}
            >
              <Text variant="heading">
                <Link
                  to={`/settings/schemas/${schema.id}`}
                  className="no-underline hover:underline"
                >
                  {schema.title}
                </Link>
              </Text>
              <Modal
                accessibleTitle={t`settings.schemas.removeTitle`}
                trigger={({open}): ReactElement => (
                  <Button
                    title={t`settings.remove`}
                    variant="text"
                    slim
                    size="xs"
                    onClick={open}
                  >
                    <TrashIcon width={14} height={14} />
                  </Button>
                )}
              >
                {({close}): ReactElement => (
                  <SchemaDelete close={close} schema={schema} />
                )}
              </Modal>
            </div>
            <Link
              to={`/settings/schemas/${schema.id}`}
              className="no-underline"
            >
              <div
                className={`
                  text-ui-600
                  grid
                  gap-1
                  px-3
                  py-2
                `}
              >
                <div className={`flex items-center gap-1`}>
                  <InformationCircleIcon
                    className="dark:text-ui-300 text-ui-500"
                    width={14}
                    height={14}
                  />
                  <Text color="inherit" variant="bodySmall">
                    {schema.description || `Schema for ${schema.title}`}
                  </Text>
                </div>
                <div className={`flex items-center gap-1`}>
                  <TableCellsIcon
                    className="dark:text-ui-300 text-ui-500"
                    width={14}
                    height={14}
                  />
                  <Text color="inherit" variant="bodySmall">
                    {t(`settings.schemas.schemaList.dataRows`, {
                      count: schema.datasetsCount,
                    })}
                  </Text>
                </div>
                <div
                  className={`
                    dark:text-ui-400/80
                    text-ui-600
                    mt-0.5
                    min-w-0
                  `}
                >
                  <Text color="inherit" variant="tiny">
                    <span
                      title={formattedName}
                      className={`
                        block
                        overflow-hidden truncate
                        text-ellipsis
                      `}
                    >
                      {profilesLoading ? (
                        <span
                          className={`
                            text-tiny
                            bg-ui-200
                            text-transparent
                          `}
                        >
                          {t`settings.schemas.nameLoading`}
                        </span>
                      ) : (
                        (profilesError && t`settings.errored`) ||
                        `${createdBy} ${createdOn}`
                      )}
                    </span>
                  </Text>
                </div>
              </div>
              <div
                className={`
                  relative
                  overflow-hidden
                  pl-3
                `}
              >
                <DisplayTable fields={schema.descendants} />
                <div
                  className={`
                    to-ui-0
                    dark:to-ui-100
                    absolute
                    bottom-0
                    right-0
                    h-full
                    w-[20%]
                    bg-gradient-to-r
                    from-transparent
                  `}
                />
              </div>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default SchemaCardList
