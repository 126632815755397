import {format, utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz'

export const timeStyles = {
  full: `full`,
  long: `long`,
  medium: `medium`,
  short: `short`,
} as const

export type TimeStyle = (typeof timeStyles)[keyof typeof timeStyles]

export const dateStyles = {
  full: `full`,
  long: `long`,
  medium: `medium`,
  short: `short`,
} as const

export type DateStyle = (typeof dateStyles)[keyof typeof dateStyles]

export const numDateStyles = {
  numeric: `numeric`,
  twoDigit: `2-digit`,
} as const

export type NumDateStyle = (typeof numDateStyles)[keyof typeof numDateStyles]

export interface DateTimeOptions {
  timeZone: string
  dateStyle?: DateStyle
  locale?: string
  timeStyle?: TimeStyle
  hour12?: boolean
  year?: NumDateStyle
  month?: NumDateStyle
  day?: NumDateStyle
}

export const getUserTimezoneTime = (utcDateTime: string, timeZone: string) => {
  try {
    // Convert utc date string to timezone date
    const timezoneDateStr = utcToZonedTime(utcDateTime, timeZone)
    const formattedLocalTime = format(timezoneDateStr, `yyyy-MM-dd HH:mm`, {
      timeZone,
    })

    //Format the zoned date to datetime picker format -> 2022-02-08T22:41
    const dateArr = formattedLocalTime.split(` `)
    return `${dateArr[0]}T${dateArr[1]}`
  } catch {
    return utcDateTime
  }
}

export const getDefaultTimezone = () =>
  new Intl.DateTimeFormat().resolvedOptions().timeZone

export const toLocaleTimezone = (
  date: string,
  options: DateTimeOptions
): string => {
  try {
    const {locale, dateStyle, timeStyle, timeZone, year, month, day} = options
    const tzLocale = locale ? locale : `default`
    const dStyle: Partial<DateTimeOptions> =
      year && month && day
        ? {year, month, day}
        : dateStyle
        ? {dateStyle, hour12: true}
        : {dateStyle: `short`, hour12: true}

    let dateTimeOptions: DateTimeOptions = {
      ...dStyle,
      timeZone: timeZone ? timeZone : getDefaultTimezone(),
    }

    dateTimeOptions = timeStyle
      ? {...dateTimeOptions, timeStyle}
      : dateTimeOptions
    return new Intl.DateTimeFormat(tzLocale, dateTimeOptions).format(
      new Date(date)
    )
  } catch {
    return ``
  }
}

export const toUTC = (
  dateTime: Date,
  timezone = getDefaultTimezone()
): string => {
  try {
    const utcDateTime = zonedTimeToUtc(dateTime, timezone)
    return utcDateTime.toISOString()
  } catch {
    return null
  }
}
